import * as React from "react"
import PropTypes from "prop-types"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar } from "react-bootstrap"
import NavImageLogo from "../images/logo.png"


const Header = ({ siteTitle }) => (
  <header>
    <Navbar collapseOnSelect className="py-4 shadow-sm rounded" bg="light" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand>
          <img
            src={NavImageLogo}
            width="32"
            height="32"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/"><h4>Home</h4></Nav.Link>
            <Nav.Link href="talent"><h4>Talent</h4></Nav.Link>
            <Nav.Link href="project"><h4>Project</h4></Nav.Link>
            <Nav.Link href="about"><h4>About</h4></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
