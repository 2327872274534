/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import "./layout.css"

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
      </div>

      <Container>
        <hr></hr>
        <footer class="px-4">
          <Row xs={1} md={2} lg={3} >
            <Col>
              <h5>Social Media</h5>
              <ul class="nav flex-column d-flex">
                <Link to=""><li><i class="fab fa-instagram fa-2x"></i></li></Link>
                <li><i class="fab fa-instagram fa-2x"></i></li>
                <li><i class="fab fa-instagram fa-2x"></i></li>
              </ul>
            </Col>

            {/* <Col>
              <h5>Category</h5>
              <ul class="nav flex-column">
                <li class="nav-item mb-2"><a href="/category/orang-kudus/" class="nav-link p-0 text-muted">Orang Kudus</a></li>
                <li class="nav-item mb-2"><a href="/category/natal/" class="nav-link p-0 text-muted">Natal</a></li>
                <li class="nav-item mb-2"><a href="/" class="nav-link p-0 text-muted">Kategory</a></li>
                <li class="nav-item mb-2"><a href="/" class="nav-link p-0 text-muted">Kategory</a></li>
              </ul>
            </Col> */}

            <Col>
              <form>
                <h5>Subscribe to our newsletter</h5>
                <p>Monthly digest of whats new and exciting from us.</p>
                <div class="d-flex w-100 gap-2">
                  <label for="newsletter1" class="visually-hidden">Email address</label>
                  {/* <input id="newsletter1" type="text" class="form-control" placeholder="Email address"> */}
                  <button class="btn btn-primary" type="button">Subscribe</button>
                </div>
              </form>
            </Col>
          </Row>

          <div class="d-flex justify-content-between py-4 my-4 border-top">
            <p>© 2022 SK We Produce All rights reserved.</p>

          </div>
        </footer>

      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
