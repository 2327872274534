import React from "react"
import { graphql } from "gatsby" //highlight-line
import Layout from "../components/layout"
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from "react-bootstrap"
import styled from "styled-components";
import { Helmet } from "react-helmet";



const Section1 = styled.section`
.noBottomPadding {
    padding-bottom: 0px;
}
`;

export default function talent({ data }) {
    return (
        <Layout>
            <Helmet>
                <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
            </Helmet>
            <SEO title="project" />

            <Section1>
                <br></br>
                <Container>
                    <Row xs={1} md={2} lg={2}>
                        {data.allWpTalent.edges.map(edges => (
                            <Col md={6}>
                                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                    <div class="col p-4 d-flex flex-column position-static">
                                        <Image variant="top" src={edges.node?.talentdetails?.profilePicture?.sourceUrl} width="852" height="480" alt={edges.node?.talentdetails?.profilePicture?.altText} fluid />
                                        <h3 class="mb-0 SerifFont"><b>{edges.node?.title}</b></h3>
                                        <h3 class="mb-0 SerifFont"><b>{edges.node?.talentdetails?.igLink}</b></h3>
                                        <h3 class="mb-0 SerifFont"><b></b></h3>
                                        <br></br>

                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Section1>
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    allWpTalent {
        edges {
          node {
            id
            title
            talentdetails {
              fieldGroupName
              igLink
              name
              profilePicture {
                altText
                sourceUrl
              }
            }
          }
        }
      } 
}
`